import React from "react";

import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import authActions from "../../redux/futures/auth/actions";
import * as types from "../../redux/futures/auth/types";
import queryString from "query-string";
import Input from "../../components/Input";
import Iframe from "../../components/Iframe";
import _ from "lodash";
import appConst from "../../config/const";
import { authEndpoint } from "../../config/config";
import LadiIframe from "./LadiIframe";
import PoweredBy from "../../components/PoweredBy";
import LadiAppLogo from "../../components/LadiAppLogo";
import baseHelper from "../../helpers/BaseHelper";
import LadiLanguage from "../../components/LadiLanguage";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import i18n from "../../i18n";
class SigninV3 extends React.Component {
  constructor(props) {
    super(props);
    const email = localStorage.getItem("email");
    const parsed = queryString.parse(this.props.location.search);
    let affilateCode = baseHelper.getCookie(appConst.AFFILATE_CODE);
    let ladiflowRef = baseHelper.getCookie(appConst.LADIFLOW_REF);
    if (parsed.ref && parsed.ref != '') {
      baseHelper.setCookie(appConst.AFFILATE_CODE, parsed.ref);
      affilateCode = parsed.ref;
    }
    if (parsed.affilate_code && parsed.affilate_code != '') {
      baseHelper.setCookie(appConst.AFFILATE_CODE, parsed.affilate_code);
      affilateCode = parsed.affilate_code;
    }
    if (parsed.ladiflow_ref && parsed.ladiflow_ref != '') {
      baseHelper.setCookie(appConst.LADIFLOW_REF, parsed.ladiflow_ref);
      ladiflowRef = parsed.ladiflow_ref;
    }


    let ladiClientID = parsed.LADI_CLIENT_ID;
    let referralID = baseHelper.getCookie(appConst.REFERRAL_ID);
    let accessTradeUtm = baseHelper.getCookie(appConst.ACCESS_TRADE.UTM_SOURCE);
    let accessTradeSid = baseHelper.getCookie(appConst.ACCESS_TRADE.AFF_SID);
    // console.log(parsed);
    if (referralID != undefined && referralID != "") {
      parsed.referral_id = referralID;
    }
    if (affilateCode != undefined && affilateCode != "") {
      parsed.affilate_code = affilateCode;
    }
    if (ladiflowRef != undefined && ladiflowRef != "") {
      parsed.ladiflow_ref = ladiflowRef;
    }
    if (accessTradeUtm != undefined && accessTradeUtm != "") {
      parsed.utm_source = accessTradeUtm;
    }

    if (accessTradeSid != undefined && accessTradeSid != "") {
      parsed.aff_sid = accessTradeSid;
    }
    if (ladiClientID != undefined && ladiClientID != "") {
      baseHelper.setCookie(appConst.LADI_CLIENT_ID, ladiClientID);
    }
    const search = queryString.stringify(parsed);
    this.state = {
      email: email && email != "" ? email : "",
      password: "",
      errorPassword: "",
      refObject: parsed,
      loading: false,
      typeInput: "password",
      errorObject: {
        email: false,
        password: false
      },
      iconClass: "icon-password-hide",
      classNormal: "ladiui btn btn-primary full-width",
      classLoading: "ladiui loader btn btn-primary full-width",
      classApply: "ladiui btn btn-primary full-width",
      isLogout: false,
      search: search,
      path: props.location.pathname,
      iPayLogo: null,
      layout: 1
    };

    this.inputsRef = new Set();
    this.ref = React.createRef();
  }

  componentDidUpdate() {
    // document.title = "Đăng nhập LadiApp I All-in-One application for businesses";
  }

  componentDidMount() {

    const lang = baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi';
    i18n.changeLanguage(lang);

    if (window.location.href.indexOf('logout=1') >= 0) {
      baseHelper.removeCookie(appConst.USER_INFO);
      baseHelper.removeCookie(appConst.LADI_UID_TOKEN);
    }
    window.parent.postMessage(JSON.stringify({
      ladiuid_signin: true, callback_url: (function () {
        var urlParams = new window.URLSearchParams(window.location.search);
        return urlParams.get('callback_url');
      })()
    }), '*');

    const parsed = queryString.parse(this.props.location.search);
    if (parsed.callback_url && parsed.callback_url.indexOf('app.ipay.com.vn') >= 0) {
      this.setState({
        iPayLogo: 'https://w.ladicdn.com/ladisales/ipay.svg'
      });
    }
    if (parsed.iframe) {
      this.isIframe = true;
    }
    if (parsed.layout) {
      this.setState({ layout: parsed.layout });
    }
    document.title = "Đăng nhập LadiApp I All-in-One application for businesses";

  }

  componentWillMount() {
    this.redirectAuth(this.props);
  }

  redirectAuth(props) {
    const parsed = queryString.parse(this.props.location.search);

    if (parsed.logout) {

    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action === types.SIGNIN) {
      this.setState({
        loading: false,
        classApply: this.state.classNormal,
      });
      if (nextProps.status) {
        localStorage.setItem("email", this.state.email);
        let parsed = {};
        if (this.state.search) {
          parsed = queryString.parse(this.props.location.search);
        }
        const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);

        console.log(' send parent:', token)
        window.parent.postMessage(JSON.stringify({
          ladi_uid: {
            token: nextProps.data.token,
            is_auth: true
          }
        }), '*');
        if (!parsed.callback_url) this.props.history.push("/dashboard");
      } else {
        this.setState({ errorPassword: nextProps.message })
        // window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      }
    }

    if (
      nextProps.action === types.GET_URL_FACEBOOK ||
      nextProps.action === types.GET_URL_GOOGLE
    ) {
      if (nextProps.status) {
        if (this.isIframe) {
          window.parent.postMessage(JSON.stringify({
            ladi_uid: {
              redirect_url: nextProps.url
            }
          }), '*');
          window.parent.location.href = nextProps.url;
        } else {
          window.location.href = nextProps.url;
        }
      } else {
        window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), nextProps.message, 'OK');
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signIn = (event) => {
    event.preventDefault();
    const { errorObject } = this.state;
    if (window.gtag) {
      window.gtag('event', 'Signin_btn_login_click', {
        'event_category': 'LadiUID',
        'event_label': 'Test'
      });
    }
    // Validate
    let errors = [];
    const inputsRef = _.compact(Array.from(this.inputsRef));
    _.map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
        if (ref.validate().length > 0) {
          errorObject[ref.props.name] = ref.validate()[0];
        } else {
          errorObject[ref.props.name] = false;
        }
      }
    });

    _.compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      // window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), errorMessage, 'OK');
      this.setState({ errorObject, errorPassword: "" })
    } else {
      const parsed = queryString.parse(this.props.location.search);

      const { email, password } = this.state;
      const data = {
        email: email.trim(),
        password: password.trim(),
      };
      // if (!baseHelper.passwordValidate(password.trim())) {
      //   errorObject.password = i18n.t('AUTH.PASSWORD_INVALID');
      //   this.setState({ errorObject, errorPassword: "" });
      //   return;
      // }
      //   this.setState({ errorPassword: i18n.t('AUTH.PASSWORD_INVALID') })
      //   return;
      // } else {
      // this.setState({ errorPassword: "" }, () => {
      if (parsed.callback_url) {
        data.url_callback = parsed.callback_url;
      }
      if (parsed.app_code) {
        data.app_code = parsed.app_code;
      }
      if (parsed.store_id) {
        data.store_id = parsed.store_id;
      }
      data.search = this.state.search;
      data.path = this.state.path;

      this.props.signIn(data);
      this.state.loading = true;
      this.state.classApply = this.state.classLoading;
      // });
      // }


    }
  };

  geturlFaceBook = () => {
    const { refObject } = this.state;
    delete refObject.logout;

    if (window.gtag) {
      window.gtag('event', 'Facebook_btn_login_click', {
        'event_category': 'LadiUID',
        'event_label': 'Test'
      });
    }
    if (this.isIframe && !refObject.callback_url) {
      refObject.app_code = 'lp';
      refObject.callback_url = appConst.DEFAULT_CALLBACK_URL;
    }
    delete refObject.iframe;
    delete refObject.layout;
    this.props.getUrlFacebook(refObject);
  };

  geturlGoogle = () => {
    const { refObject } = this.state;
    delete refObject.logout;
    if (window.gtag) {
      window.gtag('event', 'Google_btn_login_click', {
        'event_category': 'LadiUID',
        'event_label': 'Test'
      });
    }
    delete refObject.iframe;
    delete refObject.layout;
    this.props.getUrlGoogle(refObject);
  };

  showPassword = () => {
    const { typeInput } = this.state;
    if (typeInput == "password") {
      this.setState({
        typeInput: "text",
        iconClass: "icon-password-view",
      });
    }
    if (typeInput == "text") {
      this.setState({
        typeInput: "password",
        iconClass: "icon-password-hide",
      });
    }
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.signIn(e);
    }
  };
  tracking = () => {
    if (window.gtag) {
      window.gtag('event', 'register_btn_login_click', {
        'event_category': 'LadiUID',
        'event_label': 'Test'
      });
    }
  }
  callClose = (modal) => {
    modal.stopPropagation();
    if (modal.target.id && (modal.target.id == 'layout-2' || modal.target.id == 'close-login-frame')) {
      if (window.parent) {
        window.parent.postMessage(JSON.stringify({
          ladi_uid: {
            is_close_iframe: true
          }
        }), '*');
      }
    }
  }

  render() {
    const { email, password, errorPassword, typeInput, iconClass, layout, errorObject } = this.state;
    const search = this.props.location.search;
    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
    const parsed = queryString.parse(this.props.location.search);
    if (token) {
      let parsed = {};
      if (this.state.search) {
        parsed = queryString.parse(this.props.location.search);
      }
      // if (!parsed.callback_url) window.location.href = "/account/profile";
    }
    return (
      <div>
        {isMobile ?
          <React.Fragment>
            {!token && (
              <div id={'layout-' + layout} className={"ladiui auth is-mobile" + (' layout-' + layout)} onClick={this.callClose} >
                <div className="ladiui-login-wrap" style={this.state.iPayLogo ? { width: '100%' } : {}}>
                  <div className='auth-form-content'>
                    < div style={{ textAlign: "center" }}>
                      <h1 className="form-title">{i18n.t('AUTH.SIGN_IN')}</h1>
                    </div>
                    <div className="ladiui-login">
                      {
                        layout == 2 &&
                        <button
                          id="close-login-frame"
                          type="button"
                          // data-dismiss="modal"
                          // data-target="modal-register-warning"
                          className="ladiui modal-close"
                          onClick={this.callClose}
                        >
                          <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                      }

                      <div className="ladiui-login-form">
                        <div className="ladiui-form create-user-form">
                          <div className={`error-password ${errorPassword != '' ? 'is_error' : ''}`}>{errorPassword}</div>
                          <form onSubmit={this.signIn}>
                            <div className="ladiui-form-group">
                              <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                              <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="email"
                                validationName={i18n.t('AUTH.EMAIL')}
                                placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                                value={email}
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true, email: true }}
                              />
                              <div className={`error-input ${errorObject.email ? 'is_error' : ''}`}>{errorObject.email}</div>
                            </div>
                            <div className="ladiui-form-group">

                              <label className="ladiui title-text-form-lever2" style={{ display: "inline-block" }}>{i18n.t('AUTH.PASSWORD')}</label>
                              <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="password"
                                validationName={i18n.t('AUTH.PASSWORD')}
                                placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                                value={password}
                                onChange={this.onChangeInput}
                                validations={{
                                  isRequired: true,
                                  minLength: 8,
                                  maxLength: 45,
                                }}
                                type={typeInput}
                              />
                              <i
                                className={
                                  `ladiui icon ` + iconClass
                                }
                                onClick={this.showPassword}
                              ></i>
                              <div className={`error-input ${errorObject.password ? 'is_error' : ''}`}>{errorObject.password}</div>
                            </div>


                            <span className="forgot-password">
                              <NavLink
                                to={{
                                  pathname: "/forgottenpass",
                                  search: search,
                                }}
                              >
                                {i18n.t('AUTH.FOGGOT_PASSWORD')}
                              </NavLink>

                            </span>


                            <div className="ladiui-form-group">
                              <button
                                ref={this.ref}
                                className={this.state.classApply}
                                onClick={this.signIn}
                                disabled={this.state.loading}
                                type="submit"
                              >
                                <i
                                  className=""
                                  style={{
                                    display: this.state.loading ? "none" : "",
                                    paddingRight: "5px",
                                  }}
                                ></i>
                                {i18n.t('AUTH.SIGN_IN')}
                              </button>
                            </div>
                          </form>
                        </div>

                      </div>




                      <span className="login-with">
                        <h1>{i18n.t('AUTH.OR_2')} </h1>
                      </span>
                      <div className="social-login">

                        <div className="social-button">
                          <a
                            href="#"
                            className="ladiui btn login-google "
                            onClick={this.geturlGoogle}
                          >
                            <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg"></img>
                            <span>Google</span>
                          </a>
                          <a
                            href="#"
                            className="ladiui btn login-facebook "
                            onClick={this.geturlFaceBook}
                          >
                            <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg"></img>
                            <span>Facebook</span>
                          </a>

                        </div>
                      </div>
                      < div style={{ textAlign: "center" }}>

                        {appConst.ALLOW_RESISTER && (
                          <div>

                            <div className="suggest-form">
                              <div>{i18n.t('AUTH.NO_HAVE_ACCOUNT')}</div>
                              <div>{layout != 1 && <NavLink
                                to={{
                                  pathname: "/signup",
                                  search: search,
                                }}
                              >
                                &nbsp;{i18n.t('AUTH.SIGN_UP_NOW')}
                              </NavLink>
                              }
                              </div>
                              {layout == 1 && <a href={`/signup${search}`} onClick={this.tracking}>&nbsp;{i18n.t('AUTH.SIGN_UP_NOW')}</a>}

                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                    {layout == 1 && <PoweredBy />}
                  </div>
                </div>

              </div>
            )
            }
            {
              parsed.logout && (
                <div>
                  {_.map(authEndpoint.LOG_OUT_URL, (value, key) => (
                    <Iframe src={value} key={key} />
                  ))}
                </div>
              )
            }

          </React.Fragment>
          :
          <React.Fragment>
            {!token && (
              <div id={'layout-' + layout} className={"ladiui auth" + (' layout-' + layout)} onClick={this.callClose} >
                {(!this.state.iPayLogo && layout == 1) ? <LadiIframe /> : <></>}
                <div className="ladiui-login-wrap" style={this.state.iPayLogo ? { width: '100%' } : {}}>
                  <div className='auth-form-content'>
                    < div style={{ textAlign: "center" }}>
                      <h1 className="form-title">{i18n.t('AUTH.SIGN_IN')}</h1>
                    </div>
                    <div className="ladiui-login">
                      {
                        layout == 2 &&
                        <button
                          id="close-login-frame"
                          type="button"
                          // data-dismiss="modal"
                          // data-target="modal-register-warning"
                          className="ladiui modal-close"
                          onClick={this.callClose}
                        >
                          <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                      }

                      <div className="ladiui-login-form">
                        <div className="ladiui-form create-user-form">
                          <div className={`error-password ${errorPassword != '' ? 'is_error' : ''}`}>{errorPassword}</div>
                          <form onSubmit={this.signIn}>
                            <div className="ladiui-form-group">
                              <label className="ladiui title-text-form-lever2">{i18n.t('AUTH.EMAIL')}</label>
                              <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="email"
                                type='email'
                                validationName={i18n.t('AUTH.EMAIL')}
                                placeholder={i18n.t('AUTH.FILL_YOUR_EMAIL')}
                                value={email}
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true, email: true }}
                              />
                              <div className={`error-input ${errorObject.email ? 'is_error' : ''}`}>{errorObject.email}</div>
                            </div>
                            <div className="ladiui-form-group">

                              <label className="ladiui title-text-form-lever2" style={{ display: "inline-block" }}>{i18n.t('AUTH.PASSWORD')}</label>
                              <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="password"
                                validationName={i18n.t('AUTH.PASSWORD')}
                                placeholder={i18n.t('AUTH.FILL_YOUR_PASS')}
                                value={password}
                                onChange={this.onChangeInput}
                                validations={{
                                  isRequired: true,
                                  minLength: 8,
                                  maxLength: 45,
                                }}
                                type={typeInput}
                              />
                              <i
                                className={
                                  `ladiui icon ` + iconClass
                                }
                                onClick={this.showPassword}
                              ></i>
                              <div className={`error-input ${errorObject.password ? 'is_error' : ''}`}>{errorObject.password}</div>
                            </div>


                            <span className="forgot-password">
                              <NavLink
                                to={{
                                  pathname: "/forgottenpass",
                                  search: search,
                                }}
                              >
                                {i18n.t('AUTH.FOGGOT_PASSWORD')}
                              </NavLink>

                            </span>


                            <div className="ladiui-form-group">
                              <button
                                ref={this.ref}
                                className={this.state.classApply}
                                onClick={this.signIn}
                                disabled={this.state.loading}
                                type="submit"
                              >
                                <i
                                  className=""
                                  style={{
                                    display: this.state.loading ? "none" : "",
                                    paddingRight: "5px",
                                  }}
                                ></i>
                                {i18n.t('AUTH.SIGN_IN')}
                              </button>
                            </div>
                          </form>
                        </div>

                      </div>




                      <span className="login-with">
                        <h1>{i18n.t('AUTH.OR_2')} </h1>
                      </span>
                      <div className="social-login">

                        <div className="social-button">
                          <a
                            href="#"
                            className="ladiui btn login-google "
                            onClick={this.geturlGoogle}
                          >
                            <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-_google.svg"></img>
                            <span>Google</span>
                          </a>
                          <a
                            href="#"
                            className="ladiui btn login-facebook "
                            onClick={this.geturlFaceBook}
                          >
                            <img src="https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg"></img>
                            <span>Facebook</span>
                          </a>

                        </div>
                      </div>
                      < div style={{ textAlign: "center" }}>

                        {appConst.ALLOW_RESISTER && (
                          <div>

                            <div className="suggest-form">
                              {i18n.t('AUTH.NO_HAVE_ACCOUNT')}
                              {layout != 1 && <NavLink
                                to={{
                                  pathname: "/signup",
                                  search: search,
                                }}
                              >
                                &nbsp;{i18n.t('AUTH.SIGN_UP_NOW')}
                              </NavLink>
                              }
                              {layout == 1 && <a href={`/signup${search}`} onClick={this.tracking}>&nbsp;{i18n.t('AUTH.SIGN_UP_NOW')}</a>}

                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                    {layout == 1 && <PoweredBy />}
                  </div>
                </div>

              </div>
            )
            }
            {
              parsed.logout && (
                <div>
                  {_.map(authEndpoint.LOG_OUT_URL, (value, key) => (
                    <Iframe src={value} key={key} />
                  ))}
                </div>
              )
            }
          </React.Fragment>
        }
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (data) => dispatch(authActions.signIn(data)),
    getUrlFacebook: (refObject) =>
      dispatch(authActions.getUrlFacebook(refObject)),
    getUrlGoogle: (refObject) => dispatch(authActions.getUrlGoole(refObject)),
  };
};

const mapStateToProps = (state) => ({ ...state.auth });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SigninV3));
