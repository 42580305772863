import appConst from '../config/const';
import { authEndpoint } from '../config/config';
import appJson from '../config/config';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { Base64 } from 'js-base64';
import _ from 'lodash';
import axios from 'axios';
import psl from 'psl';
import i18n from "../i18n";

class BaseHelper {


    getPrettyDate(dateOld) {
        if (!dateOld || dateOld == "") return "--";
        const theDate = new Date(dateOld);
        const today = new Date();
        let diffSecond = Math.floor(this.diffDateSecond(today, theDate));
        console.log('diffSecond = ', diffSecond);
        if (diffSecond < 60) {
            return diffSecond <= 10 ? i18n.t("TIME.JUST_SENT") : diffSecond + i18n.t("TIME.SECONDS");
        }
        let diffMinute = Math.floor(this.diffDateMinute(today, theDate));
        console.log('diffMinute = ', diffMinute);
        if (diffMinute < 60) {
            diffSecond = diffMinute % 60;
            return diffMinute + i18n.t("TIME.MINUTES"); // ' + diffSecond + ' s';
        }

        let diffHour = Math.floor(this.diffDateHour(today, theDate));
        if (diffHour <= 24) {
            diffMinute = diffMinute % 60;
            return diffHour + i18n.t("TIME.HOUR"); // ' + diffMinute + ' phút';
        }
        let diffDay = Math.floor(this.diffDateDay(today, theDate));

        return dateOld;
    }

    parseUrl(urlFull) {
        const parsed = {
            http: '', ishttps: 0, domain: '', subFolder: ''
        };
        if (urlFull === null || urlFull.length === 0) {
            return parsed;
        }
        urlFull = urlFull.replace(' ', '').toLowerCase();
        let index = urlFull.indexOf('://');
        let endURL = urlFull;
        if (index > -1) {
            parsed.http = urlFull.substr(0, index).trim();
            endURL = urlFull.substr(index + 3, urlFull.length).trim();
        }
        parsed.ishttps = parsed.http.startsWith('https:');
        index = endURL.indexOf('/');
        if (index == -1) {
            parsed.domain = endURL.trim().replace(' ', '');
        } else {
            parsed.domain = endURL.substr(0, index).trim();
            endURL = endURL.substr(index + 1, endURL.length).trim();
            index = endURL.indexOf('/');
            parsed.subFolder = index == -1 ? endURL : endURL.substr(0, index).trim();
        }
        parsed.domain = this.sanitizeUTF8(parsed.domain);
        return parsed;
    }

    sanitizeUTF8(text) {
        const vnTexts = ['á', 'à', 'ả', 'ã', 'ạ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'đ', 'é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'í', 'ì', 'ỉ', 'ĩ', 'ị', 'ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'Á', 'À', 'Ả', 'Ã', 'Ạ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Đ', 'É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ'];
        const replaceText = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'd', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'i', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'y', 'y', 'y', 'y', 'y', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'D', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'I', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'Y', 'Y', 'Y', 'Y', 'Y'];
        let index;
        for (let i = 0; i < vnTexts.length; i++) {
            index = text.indexOf(vnTexts[i]);
            if (index > -1) {
                text = text.replace(new RegExp(vnTexts[i], 'g'), replaceText[i]);
            }
        }
        return text;
    }

    generateText(length) {
        const text = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '_', '-', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'L', 'K', 'J', 'H', 'G', 'F', 'D', 'S', 'A', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
        let result = '';
        for (let i = 0; i < length; i++) {
            result += text[Math.floor(Math.random() * text.length)];
        }
        return result;
    }

    passwordValidate(password) {
        const regex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,30}$/, 'i');
        const regex2 = new RegExp(/^[A-Za-z0-9+/]{32}$/);
        return regex.test(password) || regex2.test(password);
    }

    validCallback(url) {
        try {
            return true;
            const domainObj = new URL(url);
            // console.log('domainObj:   ', domainObj);
            const nodeEnv = process.env.REACT_APP_BUILD_ENV;
            if (domainObj.hostname.startsWith('localhost')) {
                return nodeEnv !== 'production';
            }

            // if (domainObj.hostname.startsWith('localhost')) return true;

            const domainParse = psl.parse(domainObj.hostname);
            // console.log('domainParse:   ', domainParse);
            const domainName = domainParse.domain;
            return appConst.WHITE_LIST_CALLBACK.indexOf(domainName) >= 0;
        } catch (err) {
            console.log(err);
            return false;
        }
    }


    getFromDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0,
        });

        return _date;
    }

    getToDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999,
        });

        return _date;
    }

    getMoment() {
        if (!this.moment) {
            this.moment = moment;
        }

        return this.moment;
    }

    diffDateDay(dt1, dt2) {
        return (dt1.getTime() - dt2.getTime()) / 1000 / 84600;
    }

    diffDateHour(dt1, dt2) {
        // So sánh ngày dt1 hơn ngày dt2 bao nhiêu gio
        return (dt1.getTime() - dt2.getTime()) / 1000 / 60 / 60;
    }

    diffDateSecond(dt1, dt2) {
        // So sánh ngày dt1 hơn ngày dt2 bao nhiêu giây
        return (dt1.getTime() - dt2.getTime()) / 1000;
    }
    diffDateMinute(dt1, dt2) {
        return ((dt1.getTime() - dt2.getTime()) / 1000) / 60;
    }

    formatStrToDate(strDate, format = appConst.DEFAULT_FORMAT_DATE) {
        try {
            if (strDate == '' || strDate == null) return '';
            const date = new Date(strDate);
            return moment(date).format(format);
        } catch (e) {

        }

        return '';
    }

    formatNumber(str, _default = 0) {
        if (!str) {
            return 0;
        }

        return str.toLocaleString('en-EN', { minimumFractionDigits: _default })
    }

    numberWithCommas(x) {
        if (!x) {
            return '';
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    resetState(state) {
        const newState = { ...state }
        resetValueObject(newState);
        return newState;
    }

    setCookie(key, value, day) {
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + day);
        const cookies = new Cookies();
        cookies.set(key, value, { path: '/', expires: tomorrow, secure: true });
        // console.log('json :', { path: '/', expires: tomorrow, httpOnly: true, secure: true });
    }

    getCookie(key) {
        const cookies = new Cookies();

        return cookies.get(key);
    }

    removeCookie(key) {
        const cookies = new Cookies();
        var options = { path: '/', secure: true };
        cookies.remove(key, options);
    }

    getTimeZoneInfo(zone) {
        // create Date object for current location
        var d = new Date();

        // convert to msec
        // add local time zone offset
        // get UTC time in msec
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

        // create new Date object for different city
        // using supplied offset
        var nd = new Date(utc + (3600000 * zone));

        // return time as a string
        return {
            nd: nd.toLocaleString()
        };

    }

    parseFloat(value, _default = 0, fix_length = 2) {
        if (this.isEmpty(value) || isNaN(value)) {
            return parseFloat(parseFloat(_default).toFixed(fix_length));
        }

        return parseFloat(parseFloat(value).toFixed(fix_length));
    }

    getFirstLetter(name) {
        if (name) {
            const array1 = name.split(' ');
            let newarray1 = [];

            for (let x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].trim().charAt(0).toUpperCase());
            }
            const str = newarray1.join(' ');
            if (str.length > 2) {
                return str.substring(0, 3);
            }
            return str;
        }
        return 'UID';
    }

    base64Encode(string) {
        return Base64.encode(string);
    }

    base64Decode(string) {
        return Base64.decode(string);
    }

    isEmpty(...values) {
        let result = false;

        _.map(values, (value) => {
            if (!(_.isNumber(value) || _.isBoolean(value)) && _.isEmpty(value)) {
                result = true;
                return;
            }
        });

        return result;
    }

    async sendRequest(options) {
        await axios(options).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
    }

    async signoutAll() {
        _.map(authEndpoint.LOG_OUT_URL, async (value, key) => {
            const options = {
                method: 'GET',
                url: value,
                headers: { 'Content-Type': 'application/json' },
                data: {}
            };
            await axios(options).then(function (response) {
                // console.log(value , response);
                return response;
            }).catch(function (error) {
                return error;
            });
        })

    }
}

function resetValueObject(obj) {
    if (obj !== null && typeof obj === 'object') {
        for (const prop in obj) {
            if (typeof obj[prop] === 'object') {
                resetValueObject(obj[prop]);
            }

            if (typeof obj[prop] === 'string' || obj[prop] instanceof String) {
                obj[prop] = '';
            }
        }
    }
}
export default new BaseHelper();